@import '../../../../colors.scss';
@import '../../../../helper.scss';

$phone-width: 100px;
$desktop-width: 150px;
$gap: 15px;

.ef-pates-subheader {
    padding: 40px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include for-phone-only() {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
    }

    .ef-left {
        display: flex;
        flex-wrap: wrap;
        gap: $gap;
        .ef-select {
            min-width: $desktop-width;
        }
    
        .ef-search {
            width: $desktop-width;
        } 
    
        @include for-phone-only() {
            margin: 0;
            padding-top: calc($gap + 5px);
            padding-bottom: calc($gap + 5px);
            padding-left:0;
            .ef-select {
                min-width: $phone-width;
            }
        
            .ef-search {
                width: $phone-width;
            }
    
            .ef-select, .ef-search {
                input, select {
                    font-size: 11px;
                    background-position-y: 5px;
                }
            }

            .pagination {
                display: auto;
            }
        }
    }

    .ef-right{
        display: block;
        .ef-select{
            width: 120px;
        }

        .pagination {
            display: none;
            span {
                border-radius: 100%;
                width: 10px;
                height: 10px;
                background: red;
            }
        }
    }
}


.sticky-report-header {
    position: sticky;
    top: 120px;
    background-color: white;
}