@import '../../colors.scss';
@import "../../helper.scss";

.ef-fichar-header {
    margin: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include for-phone-only() {
        margin: auto;
        gap: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: space-around;
    }
}
