@import "../../../../colors.scss";

.adjuntos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.attached-files-wrapper {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
  width: 100%;
}

.attached-file {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: $primary;
}