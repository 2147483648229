@import '../../colors.scss';

.password-modal {
    color: $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 500px;
    background-color: white;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    outline: none;

    .header {
        background-color: #59dcf4;
        border-radius: 25px;
        border:#59dcf4 1px solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        padding: 30px 0px;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        padding: 25px 30px;
    }
}