@import "../../helper.scss";

header {
    background-image: linear-gradient(180deg, rgba(89,220,244,1) 0%, rgba(7,85,119,1) 100%);
    width: 100%;
    border-bottom-left-radius: 25px;
    height: 90px;
    position: relative;
}

.header-avatar,
.header-badge {    
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
}

.header-avatar {
    background-image: url('../../assets/images/company.png');
}

.header-badge {
    background-image: url('../../assets/images/icons/notification-bell-32.png');
}

.header-container {
    display: flex;
    align-items: center;    
}

.header-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 137px;
    height: auto;
}

@include for-phone-only() {
    .header-logo {
        position: absolute;
        top: 70%;
        
    }
}