@import "../../colors.scss";
@import "../../helper.scss";

.ef-select select {
  background-position-y: 8px;
}

.new-parte-title {
  text-align: center;
  color: $primary;
  margin-bottom: 50px;
  margin-top: 20px;

  @include for-phone-only() {
    margin: 10px 0px 15px 0px;

    display: none;
  }
}

.new-parte-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  padding-bottom: 20px;
}

.paper-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #075577;
  height: 40px;
  line-height: 40px;

  h5 {
    padding-left: 19px;
    color: $secondary;
    font-size: 19px;
  }

  @include for-phone-only() {
    h5 {
      padding-left: 0px;
      text-align: center;
    }
  }
}

.botonera {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px
}

.new-parte-save-button {
  width: 60%;
  max-width: 228px;
}

.form-container-paper {
  display: flex;
  flex-direction: column;
}

@include for-phone-only() {
  .MuiPaper-root .MuiBox-root {margin-top: 10px;}
}