@import "../../../../colors.scss";

.config-container {
  padding: 24px 0 24px 0;
  border-bottom: 1px dotted $primary;
}

.cache-info-title {
  font-size: 30px;
  color: #075577;
}

.cache-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.cache-info-table {
  -webkit-box-shadow: 0px 1px 7px 0px rgba(2, 1, 1, 0.2);
  -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100%;
    
  th {
    padding: 6px  12px;
  }

  td {
    padding: 0px 12px;
  }
  
  th {
    font-size: 18px;
    color: #075577;
    text-align: start;
  }

  td {
    font-size: 14px;
  }
}

.cache-info-td-center {
  text-align: center !important;
  height: 2rem;
}