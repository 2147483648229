@import '../../colors.scss';

.modules {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
    gap: 40px;
}

.modules_backoffice {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}

.modules__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    width: 250px;
    height: 200px;
    border-radius: 10px;
    padding: 20px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(126, 126, 126, 0.75);
    color: $primary;
}

/* Define styles for the title, icon and description */
.modules__item__title {
    font-weight: bold;
    font-size: 20px;
}

.modules__item__icon {
    font-size: 40px;
    margin-bottom: 10px;
}

.modules__item__description {
    font-size: 16px;
}

/* Change the background color on hover */
.modules__item:hover {
    background-color: $primary-transparent;
    color: white;
  
}



