@import "../../../../colors.scss";

.MuiModal-backdrop {
  background-color: $primary-transparent;
}

.notificacion-rechazado {
  background: rgb(250, 171, 171);
  min-height: 80px;
  padding: 10px;
  border-radius: 16px;
}

.see-modal {
  color: $primary;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 1045px;
  max-height: 100vh;
  overflow-y: scroll;
  width: max(386px, 80%);
  background-color: white;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  outline: none;
  .css-17w5yif-MuiPaper-root {
    box-shadow: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    h5 {
      color: $secondary;
      margin-top: 30px;
    }

    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .signatureForm {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .signature {
      width: 308px;
      height: 116px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .button-container {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    gap: 10px;    
  }
}

.edit-firma-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;

  button {
    font-size: 12px;
  }
}
