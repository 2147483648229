@import '../../colors.scss';

.backdrop {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $primary;
    color: white;
}

.navigate-backoffice-splash-button {
    margin-top: 64px;
}