@import '../../../colors.scss';

.loader-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
}

.loader-custom {
  border-radius: 4px;
  color: $primary;
  box-sizing: border-box;
  animation: animloader 0.6s linear infinite;
  margin-left: -80px;
  margin-bottom: 20px;
}