@import "./colors.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
}

h4 {
  color: $primary;
  font-size: 30px;
}

h5 {
  font-size: 20px;
  font-weight: normal;
  color: $primary;
}

p {
  font-size: 15px;
}

a {
  font-size: 18px;
}

.mt-10 {
  margin-top: 10px!important;
}

.mt-20 {
  margin-top: 20px!important;
}

.mr-10 {
  margin-right: 10px!important;
}

.mr-20 {
  margin-right: 20px!important;
}

.ml-20 {
  margin-left: 20px!important;
}

.mt-40 {
  margin-top: 40px!important;
}

.mb-40 {
  margin-bottom: 40px!important;
}

.ph-30 {
  padding-left: 30px!important;
  padding-right: 30px!important;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: 700;
}

@media (max-width: 600px) {
  .text-center-mobile {
    text-align: center;
  }
}

.spacer {
  flex-grow: 1;
}

@media (max-width: 600px) {
  label {
    text-align: center;
    margin-left: 0px;
  }
}

label {
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;
  font-weight: 800;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
