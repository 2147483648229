@import "../../../colors.scss";

.btn {
  min-width: 64px;
  padding: 6px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: $primary;
  font-family: "Lato", sans-serif;
  border-radius: 25px;
  text-transform: initial;
  font-size: 18px;
  line-height: 1.3;
  box-shadow: none;
  letter-spacing: 0.02857em;
  font-weight: 500;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin: 0;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  -webkit-box-pack: center;
  display: inline-flex;
  gap: 4px;
  &:hover:enabled {
    text-decoration: none;
    background-color: rgb(4, 59, 83);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    cursor: auto;
    opacity: 1;
    background: #efefef;
    color: gray;
    border-color: #efefef;
  }
  .icon {
    width: 19px;
  }
}

.tertiary {
  color: $secondary;
  border: 1px solid $secondary;
  background-color: #ffffff;
  &:hover:enabled {
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.secondary {
  background-color: #82d08e;
  color: $primary;
  &:hover:enabled {
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.rejected {
  color: red;
  border: 1px solid red;
  background-color: #ffffff;
  &:hover:enabled {
    opacity: 0.6;
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.validate {
  color: #82d08e;
  border: 1px solid #82d08e;
  background-color: #ffffff;
  &:hover:enabled {
    opacity: 0.6;
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.icon-tertiary {
  filter: $filter-secondary;
}
