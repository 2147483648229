@import "../../helper.scss";

.header-pending {
  margin-bottom: 16px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pending-title-container {
      display: flex;
      align-items: center;
      gap: 8px;

   

      .pending-title {
        color: #ffa600;

      

      }

      img {
        width: 26px;
        filter: invert(73%) sepia(62%) saturate(3887%) hue-rotate(359deg) brightness(105%) contrast(107%);
      }
    }
  }
  @include for-phone-only() {
    margin-bottom: 6px;
    display: flex;
    .title {
      width: 80%;
    }
    h4 {font-size: 24px;margin-bottom: 0px;}
    h5 {font-size: 18px;}


   
  }
}
