@import '../../../../../colors.scss';
@import '../../../../../helper.scss';

.avisos-validate-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
}
