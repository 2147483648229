.chat-container {
    height: 100%;
    width: 100%;



    .send-box {
        margin-top: 20px;
        tab-size: 4;
        color-scheme: light;
        line-height: inherit;
        border: 0 solid #d9d9e3;
        box-sizing: border-box;
        position: relative;
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
        border-radius: .75rem;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1);
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
        padding-bottom: 1rem;
        padding-top: 1rem;
        padding-left: 1rem;

        textarea {
            tab-size: 4;
            color-scheme: light;
            border: 0 solid #d9d9e3;
            box-sizing: border-box;
            appearance: none;
            border-color: #8e8ea0;
            border-radius: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            width: 100%;
            resize: none;
            border-width: 0;
            background-color: transparent;
            padding: 0;
            padding-left: 0;
            padding-right: 3rem;
            max-height: 200px;
            height: 24px;
            overflow-y: hidden;

            &:focus {
                outline: none;
            }
        }

        button {
            -webkit-text-size-adjust: 100%;
            tab-size: 4;
            color-scheme: light;
            border: 0 solid #d9d9e3;
            box-sizing: border-box;
            font-size: 100%;
            font-weight: inherit;
            line-height: inherit;
            position: absolute;
            border-radius: .375rem;
            transition-duration: .15s;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1);
            bottom: .75rem;
            right: .75rem;
            padding: .5rem;
        }

    }

    .rce-mbox-body {

        .rce-mbox-text.right {
            max-width: 90vw;
            overflow: auto;

        }

        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }

        table,
        th,
        td {
            border: 1px solid;
        }

        th,
        td {
            padding: 10px;
            text-align: left;
        }

        th {
            text-transform: capitalize;
        }

        .sentMessage {
            display: flex;
            gap: 25px;
        }

        .message {
            width: 80%;
        }

        .retry {

            height: 20px;
            width: 20px;
        }
    }
}