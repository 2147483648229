.new-lines {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100px;

    button {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

.header-add {
  margin-top: 20px;
}

.line {
  display: flex;
  margin-top: 25px;
  align-items: center;
  gap: 15px;

  .image-container {
    display: flex;
    border-radius: 5px;
    background-color: red;
    img {
      width: 32px;
      padding: 5px;
    }
  }
}

.line-services-container {
  margin-top: 50px;
  @media (max-width: 600px) {
      margin-top: 0px;
      h4{
        font-size: 22px;
       margin-bottom: 0px;
      }
      .logo{
        width: 25px;
        height: 25px;
      }
  }

}
