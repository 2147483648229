body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  width: 6px;
  height: 33px;
  display: inline-block;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  box-sizing: border-box;
  animation: animloader 0.6s linear infinite;
  margin-left: -90px;
  margin-bottom: 20px;
}

@keyframes animloader {
  0% {
      box-shadow: 20px -10px, 40px 10px, 60px 0px;
  }

  25% {
      box-shadow: 20px 0px, 40px 0px, 60px 10px;
  }

  50% {
      box-shadow: 20px 10px, 40px -10px, 60px 0px;
  }

  75% {
      box-shadow: 20px 0px, 40px 0px, 60px -10px;
  }

  100% {
      box-shadow: 20px -10px, 40px 10px, 60px 0px;
  }
}

@media print {
  .noPrint {
      display:none;
  }
}
@media screen {
   .onlyPrint {
       display: none;
   }
}