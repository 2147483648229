@import '../../../../colors.scss';
@import '../../../../helper.scss';

.MuiModal-backdrop {
    background-color: $primary-transparent;
}

.selectWrap {
    margin-bottom: 15px;
}

.new-obra-modal {
    color: $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 780px;
    max-height: 100vh;
    overflow-y: scroll;
    width: max(386px, 80%);
    background-color: white;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    outline: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .header {
        h5 {
            color: $secondary;
            margin-top: 30px;
        }

        border-radius: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        padding: 15px 40px;


        p {
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
        }

        label {
            font-size: 15px;
        }

        .obra-container {
            height: calc(40vh);
            max-height: 267px;
            border: 1px solid $primary;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 80px;

            .scroll {
                width: 90%;
                height: 95%;
                overflow-y: scroll;
            }

            .scroll::-webkit-scrollbar {
                width: 8px;
                border-radius: 8px;
                background-color: transparent;
                border: 1px solid $secondary;
            }

            .scroll::-webkit-scrollbar-thumb {
                background: $primary;
                border-radius: 8px;
            }

            .obra-item {
                text-align: center;
                line-height: 30px;
                font-size: 15px;
                cursor: pointer;

                &:hover {
                    background-color: rgb(7, 85, 119, 0.1);
                }
            }

            .selected {
                font-weight: 700;
                background-color: rgb(7, 85, 119, 0.1);
            }

            .duplicated {
                color: rgb(121, 49, 49);
                background-color: rgba(242, 115, 115, 0.1);
                cursor: auto;

                &:hover {
                    background-color: rgba(242, 115, 115, 0.1);
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    img {
                        width: 20px;
                        margin-right: 5px;
                        color: #805e01;
                        filter: invert(27%) sepia(27%) saturate(7474%) hue-rotate(46deg) brightness(94%) contrast(99%);
                    }
                }
            }

            .selectable {
                cursor: pointer;
            }
        }

        .button-container {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 45%;
        }
    }

    .MuiGrid-item {}

    @include for-phone-only() {
        .MuiGrid-item {
            padding: 0px;
            padding-bottom: 15px;

            & ::placeholder {
                color: $primary;
                opacity: 1;
            }

            .selectWrap {
                margin-bottom: 0px;
            }

            label {
                display: none;
            }

        }

        .header {
            display: none;
        }

        .body {
            margin-top: 50px;

            .MuiGrid-root {
                margin-left: 0px;
            }

            .text-center-mobile {
                display: none;
            }

            .obra-container {
                margin-top: 20px;
                max-height: 367px;
                // height: calc(100%);
            }
        }
    }
}