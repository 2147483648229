@import "../../../../colors.scss";
@import "../../../../helper.scss";

$phone-width: 100px;
$desktop-width: 150px;
$gap: 15px;

.ef-pates-subheader {
  padding: 40px;
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:20;
  
  @include for-phone-only() {
    display: block;
    padding: 10px;
  }

  .ef-left {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
    .ef-select {
      min-width: $desktop-width;
    }

    .ef-search {
      width: $desktop-width;
      input {
        height: 40px;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color:$primary;
          opacity: 1; /* Firefox */
        }
      }
    }

   

    .datefilter{
      width: 150px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid $primary;
      padding: 8px;
      padding-right: 40px;
      border-radius: 30px;
      color: $primary;
      outline: none;
      background: transparent;
      background-image: url("../../../../assets/images/icons/chevronIcon.png");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 8px);
      background-position-y: 10px;
      font-family: "Lato", sans-serif;
      font-size: 15px;
      text-align: center;
      cursor: pointer;
  }

  .datefilter[type="date"]{
      background-image: none;
  }

    @include for-phone-only() {
      margin: 0;
      padding: 10px 0px;
      .ef-select {
        min-width: $phone-width;
        max-width: 110px;
      }

      .ef-search {
        width: $phone-width;

        input {
          height: 35px;
        }
      }

      .ef-select,
      .datePickerFilter,
      .ef-search {
        font-size: 11px;

        input,
        select {
          font-size: 11px;
          background-position-y: 5px;
        }
      }
    }
  }

  .ef-right {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    button {
      font-size: 14px;
      min-width: 160px;
    }
  }
}
