.legend {
  display: flex;
}

dt, dd {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
}

dd{
  padding-top: 5px;
}

dt {
  width: 20px;
  margin-left: 20px;
  margin-right: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
