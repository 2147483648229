.sassWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.titleSas {
  color: rgb(255, 255, 255);
  font-size: 26px;
  font-weight: 600;
  margin: 0px;
  text-transform: uppercase;
  background-color: rgb(7, 85, 119);
  padding: 18px 36px;
}

.bodySas {
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
  box-shadow: rgba(71, 105, 107, 0.1) 0px 0px 1rem 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  background-color: rgb(255, 255, 255);
}

.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  font-size: 16px;
}

.loginInput {
  border: 1px solid rgb(216, 214, 222);
  background-color: transparent;
  color: rgb(110, 107, 123);
  font-size: 1rem;
  border-radius: 0.357rem;
  font-family: Montserrat, Helvetica, Arial, serif;
  padding: 0.438rem 1rem;
  width: 14vw;
  outline: none;
}

.loginInput.valid {
  background-color:  rgba(119, 197, 119, 0.397);
}

.loginInput:focus {
  border-color: rgb(7, 85, 119);
}

.loginButton {
  background-color: rgb(7, 85, 119);
  color: rgb(255, 255, 255);
  border-width: 0px;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  width: 14vw;
  font-weight: bold;
  cursor: pointer;
}
.redButton {
  background-color: rgba(233, 16, 16, 0.726);
  color: rgb(255, 255, 255);
  border-width: 0px;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  width: 14vw;
  font-weight: bold;
  cursor: pointer;
}

.newClientWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tabWrapper {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(216, 214, 222);
  margin-bottom: 24px;
}

.tabOption {
  padding: 20px 24px;
  font-size: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: pointer;
  transition: all .2s;
}

.tabOption:hover {
  background-color: rgba(7, 85, 119, 0.184);
}

.tabOption.selected {
  background-color: rgb(7, 85, 119);
  color: #FFFFFF
}

.newClientForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.newClientStateWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.newClientForm-button {
  width: 100%;
}

.updateButton {
  width: fit-content;
  align-self: flex-end;
}

.builds-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.builds-list-table {
  -webkit-box-shadow: 0px 1px 7px 0px rgba(2, 1, 1, 0.2);
  -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100%;
    
  th, td {
    padding: 8px 12px;
  }
  
  th {
    font-size: 18px;
    color: #075577;
    text-align: start;
  }

  td {
    font-size: 16px;
  }
}

.update-clients-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.update-clients-section-title {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #075577;
  margin-bottom: 8px;
}

.update-clients-client-box-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
}

.update-clients-client-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f4f4fa;
  border-radius: 20px;
  padding: 12px 18px;
  cursor: pointer;
  transition: all .2s;
}

.update-clients-client-box.selected {
  background-color: #075577;
  color: white;
}

.update-clients-client-box-name {
  font-size: 16px;
  font-weight: bold;
}

.update-clients-client-box-hash {
  font-size: 12px;
  font-weight: lighter;
  text-align: end;
}

.update-clients-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.update-clients-check-all-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.update-clients-check-all-label {
  margin: 0;
  color: grey;
  font-size: 14px;
  cursor: pointer;
}

.update-clients-check-all-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}