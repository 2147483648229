@import "../../../colors.scss";

.ef-select {
  .selector {
    border: 1px solid $primary;
    padding: 8px;
    padding-right: 40px;
    border-radius: 30px;
    color: $primary;
    background: white;
    background-image: url("../../../assets/images/icons/chevronIcon.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 10px;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    text-align: center;

    &.filled {
      background: $primary;
      color: white;
      background-image: url("../../../assets/images/icons/chevronIconWhite.png");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 8px);
      background-position-y: 7px;
    }

    &.disabled {
      opacity: 1;
      background: #efefef;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
    }
  }

  .options {
    position: absolute;
    background: white;
    z-index: 9999;
    border-radius: 8px;
    margin-top: 30px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
    .option {
      padding: 8px;
      cursor: pointer;
    }

    .option:nth-child(3) {
      color:green;
    }
    .option:nth-child(4) {
      color:rgb(7, 127, 255);
    }
    .option:nth-child(5) {
      color:rgb(83, 83, 188);
    }
    .option:nth-child(6) {
      color:red;
    }
  }
}
