@import '../../colors.scss';

.reset-container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.reset-background {
    background: rgb(89,220,244);
    background-image: url('/assets/images/loginBackground.png'), linear-gradient(180deg, rgba(89,220,244,1) 0%, rgba(7,85,119,1) 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.App-logo {
    max-width: calc(100vw - 100px);
    padding: 0px 50px;
}