@import '../../../../colors.scss';
@import '../../../../helper.scss';

.ef-avisos-header {
    margin: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include for-phone-only() {
        margin: auto;
        gap: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: space-around;
    }
}
