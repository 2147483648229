@import '../../../../colors.scss';

.ef-report .ef-table {
    width: 95%;
    margin: auto;
    font-size: 12px;
    .head-col button {
        display: none;
    }

    th {
        max-width: 100px !important;
    }
}