@import '../../../colors.scss';
@import '../../../helper.scss';

.ef-resume {
    position: fixed;
    bottom: 0;
    left: 40px;
    display: flex;
    background: $primary;
    color: white;
    padding: 10px;
    gap: 15px;
    border-radius: 20px 20px 0 0;

    @include for-phone-only() {
        background: none;
        justify-content: center;
        width: 100vw;
        padding: 0;
        left: 0;
        gap: 2px;

        div {
            background: $primary;
            padding: 10px;
            font-size: 12px;
            border-radius: 20px 20px 0 0;
        }
    }
}