@import "../../../colors.scss";
@import "../../../helper.scss";

.ef-select select {
  background-position-y: 8px;
}

.new-aviso-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-aviso-title-wrapper {
  text-align: center;
  color: $primary;
}

.new-aviso-body-wrapper {
  max-width: 1200px;
  padding-bottom: 30px;
}

.botonera {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px
}

.new-parte-save-button {
  width: 60%;
  max-width: 228px;
}

.form-container-paper {
  display: flex;
  flex-direction: column;
}

@include for-phone-only() {
  .MuiPaper-root .MuiBox-root {margin-top: 10px;}
}