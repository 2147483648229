@import "../../../colors.scss";
@import "../../../helper.scss";

.ef-phone-fixed {
  display: none;
}

.ef-table .table {
  margin: auto;
  width: 100%;
  background: white;
  border-radius: 20px 20px 20px 20px;
  table-layout: fixed;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 7px 0px rgba(2, 1, 1, 0.2);
  -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);

  .thead {
    .tr {
      display: flex;
    }
    
    .th {
      background: $primary;
      color: $secondary;
      padding: 14px;
      font-weight: 200;
      display: inline-block;

      .head-col {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        height: 100%;

        .up,
        .down,
        .none, 
        .download button {
          cursor: pointer;
          background: none;
          width: 15px;
          height: 15px;
          background-size: 100%;
          background-repeat: no-repeat;
          border: none;
        }


        .up {
          background-image: url("../../../assets/images/icons/single_up_chevron.png");
        }

        .down {
          background-image: url("../../../assets/images/icons/single_down_chevron.png");
        }

        .none {
          background-image: url("../../../assets/images/icons/single_right_chevron.png");
        }

        .download {
          display: flex;
          gap: 10px;
          align-items: center;

          button {
            background: none;
            border: none;
            cursor: pointer;
          }
        }
      
      }

      &:first-child {
        .head-col {
          // min-width: 200px;
        }
      }
    }
  }

  .tbody {
    .tr {
      border-bottom: 1px solid #0755774a;
    }

    .td {
      padding: 0px 10px;
      display: inline-block;
      vertical-align: text-top;
    }

    .td.text {
      text-align: center;
    }

    .td .text-image {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .extra-options {
        display: flex;
        gap: 10px;

        img {
          width: 24px;
        }

        .filter-blue {
          filter: $filter-secondary;
        }

        .filter-gray {
          filter: invert(96%) sepia(6%) saturate(0%) hue-rotate(133deg) brightness(85%) contrast(84%);
        }
      }
    }

    .td .image, .td b, .td p {
      display: inline-block;
      padding: 0px 10px;
      

      img {
        width: 16px;
      }
    }
  }

  .tfoot {
    .tr {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      padding: 4px;
    }

    .td {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .select-size {
      outline: none;
      border: 1px solid #075577;
      border-radius: 6px;
      padding: 2px;
      background-color: white;
    }
  }

  .validated-icon {
    filter: $filter-green;
  }

  .rejected-icon {
    filter: $filter-red;
  }

  .containerCheckbox {
    margin-left: 12px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #eee;
  }

  .containerCheckbox:hover input~.checkmark {
    background-color: #ccc;
  }

  .containerCheckbox input:checked~.checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containerCheckbox input:checked~.checkmark:after {
    display: block;
  }

  .containerCheckbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@include for-phone-only() {
  
}

.no-elements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}