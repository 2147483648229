.logo {
    width: 35px;
    height: 35px;
}

.containerCheckbox {
    display: inline;
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    top: 50%;
    bottom: 50%;
  }

  .containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .containerCheckbox input:disabled ~ .checkmark {
    background-color: #ccc;
    border: 1px solid #b0bec5;
    cursor: auto;
  }

  .containerCheckbox input:disabled:checked ~ .checkmark {
    background-color: #b0bec5;
  }
  
  .containerCheckbox input:disabled ~ .checkmark:after {
    border-color: #fafafa;
  }

  .containerCheckbox input:disabled ~ span {
    color: #b0bec5;
    cursor: auto;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #eee;
    border: 1px solid #2196F3;
  }

  .containerCheckbox:hover input~.checkmark {
    background-color: #ccc;
  }

  .containerCheckbox input:checked~.checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containerCheckbox input:checked~.checkmark:after {
    display: block;
  }

  .containerCheckbox .checkmark:after {
    left: 6.5px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }