@import "../../../colors.scss";

.ef-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid $primary;
  padding: 8px;
  padding-right: 40px;
  border-radius: 30px;
  color: $primary;
  width: 100%;
  outline: none;
  background: transparent;
  background-image: url("../../../assets/images/icons/chevronIcon.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 10px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  text-align: center;
  cursor: pointer;

  &.filled {
    background: $primary;
    color: white;
    background-image: url("../../../assets/images/icons/chevronIconWhite.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 7px;
  }

  &:disabled {
    opacity: 1;
    background: #efefef;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }
  option:checked {
    background: #3e8ccc;
    color: white;
  }
}

.ef-select-modified select {
  border: 2px solid #D97938;
}

.ef-select-marked {
  select {
    background-color: #075577;
    color: #FFF;
    -webkit-text-fill-color: #FFF;
    background-image: url("../../../assets/images/icons/chevronIconWhite.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 7px;

    &:disabled {
      background-color: #075577;
      -webkit-text-fill-color: #FFF;
    }
  }
  option {
    background-color: #FFF;
    color: #075577;
  }
}

.multiple {
 height: 150px;
}
