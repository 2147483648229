
@import "../../../../colors.scss";
@import "../../../../helper.scss";

.MuiModal-backdrop {
  background-color: $primary-transparent;
}
.new-confirm-modal {
  color: $primary;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 780px;
  max-height: 100vh;
  overflow-y: scroll;
  width: max(386px, 80%);
  background-color: white;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  outline: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    background-color: #82d08e;
    h5 {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: $primary;
      padding: 20px;
      .check {
        width: 20px;
        filter: $filter-primary;
      }
    }

    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    padding: 15px 50px;

    p {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
    }

    label {
      font-size: 15px;
    }

    .signature {
      background: white;
      width: 100%;
      height: 285px;
      border-radius: 30px;
      border: 1px solid $primary;
    }

    .button-container {
      margin: 40px 0px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .ef-button-nat,
      .ef-button-nat > button {
        width: 100%;
      }
    }
  }
}
.new-confirm-modal.rejected {
  &:hover{
    background-color: white;
  }
  .header {
    background-color: red;
    h5 {
      color: white;
    }
  }
  .body {
    color: red;
    background-color: white;
  }
}

@include for-phone-only() {
    
  .new-confirm-modal .body .button-container {
    width: 90%;    
  }
    
  

}
