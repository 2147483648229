@import '../../../colors.scss';

.ef-input-native {
  input {
    appearance: none;
    border: 1px solid #075577;
    padding: 8px;
    border-radius: 30px;
    color: #075577;
    width: 100%;
    outline: none;
    background: transparent;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    text-align: center;
    cursor: pointer;
  }

  input:invalid {
    border: 1px solid red !important;
  }

  input:disabled {
    opacity: 1;
    background: #efefef;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }



  .scroll {
    border: 1px solid #075577;
    display: flex;
    margin:0;
    padding:0;
    border-radius: 10px;
    color: #075577;
    width: 100%;
    outline: none;
    background: transparent;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    text-align: center;
    cursor: pointer;

    textarea {
      margin:0;
      padding:0;
      padding: 6px 15px;
      border-radius: 10px;
      border: none;
      appearance: none;
      width: 100%;
      background: transparent;
      outline: none;


      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
        background-color: transparent;
        border: 1px solid $secondary;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 8px;
      }

      &:invalid {
        border: 1px solid red !important;
      }
  
      &:disabled {
        opacity: 1;
        background: #efefef;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
      }
    }

  
  }
}

.ef-input-native-modified {
  input {
    border: 2px solid #D97938;
  }
}