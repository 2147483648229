@import "../../../colors.scss";

.ef-timepicker .placeHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary;
  padding: 8px;
  border-radius: 30px;
  color: $primary;
  font-family: "Lato", sans-serif;
  font-size: 17px;
  img {
    margin-left: 15px;
  }
}

.ef-timepicker.disabled {
  opacity: 1;
  background: #efefef;
  border-radius: 30px;
}

.ef-timepicker.ef-timepicker-modified .placeHolder {
  border: 2px solid #D97938;
}

.selector {
  background: white;
  display: flex;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.29);
  border-radius: 6px;
  position: absolute;
  margin-left:10px;
}

.selector div {
  overflow-y: scroll;
  max-height: 120px;
}

.selector div div {
  font-size: 16px;
  padding: 5px;
}

.selector div div.slot:hover {
  background: #075577;
  color: white;
  cursor: pointer;
}

.selector div div.selected {
  background: #0755778a;
  color: white;
  cursor: pointer;
}

.selector div::-webkit-scrollbar {
  display: none;
}

.selector div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

