@import '../../../colors.scss';

.ef-search input {
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    border: 1px solid $primary;
    padding: 8px;
    padding-right: 40px;
    border-radius: 30px;
    color: $primary;
    width: 100%;
    outline: none;
    background: transparent;
    background-image: url('../../../assets/images/icons/zoomIcon.png');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 7px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}