@import "../../../../colors.scss";

.MuiModal-backdrop{
    background-color: $primary-transparent;
}
.modal-confirm-validation-parte {
    color: $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 600px;
    max-height: 100vh;
    overflow-y: scroll;
    width: max(386px, 80%);
    background-color: white;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    outline: none;
    &::-webkit-scrollbar {
        display: none;
    }
    .header {
        background-color: #82d08e;
        padding: 30px;
        h5 {
            color: $primary;
            font-size: 25px;
            margin: 0;
        }
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding: 15px 50px;

        .button-container {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}