@import '../../../../colors.scss';
@import "../../../../helper.scss";

.ef-table {
    width: 95%;
    margin: auto;

    table thead tr th {
        padding: 10px;
    }

    .line-text {
        max-height: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        p {
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;


        }

        &:hover,
        &.clicked {
            max-height: none;
            overflow: visible;
            white-space: normal;

            p {
                overflow: visible;
                white-space: normal;
                max-height: none;

            }
        }
    }

}