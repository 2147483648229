@import '../../colors.scss';
@import "../../helper.scss";

.center {
    padding-top: 20px;

    h4 {
        color: $secondary;
    }

    h4,
    p {
        width: 100%;
        text-align: center;
    }

    p {
        margin-bottom: 20px;
    }
}

.ef-report-title {
    position: sticky;
    top: 0;
    background-color: white;

    @include for-phone-only() {
        margin-bottom: 6px;
        display: flex;

        .title {
            width: 80%;
        }

        h4 {
            font-size: 24px;
            margin-bottom: 0px;
        }

        p {
            display: none;
        }



    }
}

.title-rotate {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: $primary;
        font-weight: bold;
        font-size: 20px;
    }
}

 @include for-phone-only() {

     .ef-select{
        max-width: 200px;
     }
}