@import '../../../colors.scss';

.MuiFab-primary,
.MuiFab-secondary,
.MuiFab-error,
.MuiFab-warning,
.MuiFab-info,
.MuiFab-success{
    box-shadow: none;
}

.MuiButton-outlinedPrimary, .MuiButton-containedInfo, .MuiFab-info{
    span {
        filter: $filter-primary;
        -webkit-filter:  $filter-primary;
    }
}

.MuiFab-primary.outlined {
    background: #fff;
    box-shadow: none;
    border: 1px solid $primary;
    span img{
        filter: $filter-primary;
        -webkit-filter:  $filter-primary;
    }
}