@import "../../../colors.scss";
@import "../../../helper.scss";

.ef-phone-fixed {
  display: none;
}

.ef-table table {
  margin: auto;
  width: 100%;
  background: white;
  border-radius: 20px 20px 20px 20px;

  thead {
    th {
      background: $primary;
      color: $secondary;
      padding: 5px;
      font-weight: 200;
      border-radius: 20px 20px 0 0;

      .head-col {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        height: 100%;

        .up,
        .down,
        .none {
          cursor: pointer;
          background: none;
          width: 15px;
          height: 15px;
          background-size: 100%;
          background-repeat: no-repeat;
          border: none;
        }

        .up {
          background-image: url("../../../assets/images/icons/single_up_chevron.png");
        }

        .down {
          background-image: url("../../../assets/images/icons/single_down_chevron.png");
        }

        .none {
          background-image: url("../../../assets/images/icons/single_right_chevron.png");
        }
      }

      &:first-child {
        .head-col {
          min-width: 200px;
        }
      }
    }
  }

  tbody {
    // tr {
    //     &:first-child {
    //         position: absolute;
    //     }
    // }

    border-radius: 0px 0px 20px 20px;
    -webkit-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);

    td {
      padding: 10px;
    }

    td.text {
      text-align: center;
    }

    td .text-image {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .extra-options {
        display: flex;
        gap: 10px;

        img {
          width: 24px;
        }

        .filter-blue {
          filter: $filter-secondary;
        }

        .filter-gray {
          filter: invert(96%) sepia(6%) saturate(0%) hue-rotate(133deg) brightness(85%) contrast(84%);
        }
      }
    }

    td .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
      }
    }
  }

  .validated-icon {
    filter: $filter-green;
  }

  .rejected-icon {
    filter: $filter-red;
  }

  .containerCheckbox {
    margin-left: 12px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #eee;
  }

  .containerCheckbox:hover input~.checkmark {
    background-color: #ccc;
  }

  .containerCheckbox input:checked~.checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containerCheckbox input:checked~.checkmark:after {
    display: block;
  }

  .containerCheckbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@include for-phone-only() {
  
}