@import '../../../../colors.scss';
@import '../../../../helper.scss';

$phone-width: 100px;
$desktop-width: 150px;
$gap: 15px;

.ef-avisos-filtros {
    padding: 40px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include for-phone-only() {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
    }

    .datePickerFilter {
        width: 147px;

        & .MuiInputBase-root {
            width: 150px;
        }

        @include for-phone-only() {
            width: 85px;

            & .MuiInputBase-root {
                width: 90px;
            }
        }
    }

    .datefilter {
        width: 140px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid $primary;
        padding: 8px;
        padding-right: 40px;
        border-radius: 30px;
        color: $primary;
        outline: none;
        background: transparent;
        background-image: url("../../../../assets/images/icons/chevronIcon.png");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 8px);
        background-position-y: 10px;
        font-family: "Lato", sans-serif;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
    }

    .datefilter[type="date"] {
        background-image: none;
    }

    .ef-left {
        display: flex;
        flex-wrap: wrap;
        gap: $gap;

        .ef-select {
            min-width: $desktop-width;
        }

        .ef-search {
            width: $desktop-width;
        }

        @include for-phone-only() {
            margin: 0;
            padding-bottom: 10px;
            align-items: center;
            justify-content: center;
            padding-left: 0px;
            padding-top: 10px;


            .ef-select {
                min-width: $phone-width;
                max-width: 180px;
            }

            .ef-search {
                width: $phone-width;
            }

            .ef-select,
            .ef-search {

                input,
                select {
                    font-size: 11px;
                    background-position-y: 5px;
                }
            }

            .pagination {
                display: auto;
            }
        }
    }

    .ef-right {
        display: block;

        .ef-select {
            width: 120px;
            height: 35px;
        }

        .pagination {
            display: none;

            span {
                border-radius: 100%;
                width: 10px;
                height: 10px;
                background: red;
            }
        }

        @include for-phone-only() {
            align-items: center;
            justify-content: center;
            padding-bottom: 15px;


        }
    }
}