$primary: #075577;
$primary-transparent: #075577ab;
$secondary: #59dcf4;
$secondary-transparent: #a9eefaab;
$filter-primary: brightness(0) saturate(100%) invert(22%) sepia(20%)
  saturate(5212%) hue-rotate(172deg) brightness(95%) contrast(94%);
$filter-secondary: invert(69%) sepia(75%) saturate(338%) hue-rotate(144deg)
  brightness(99%) contrast(93%);
$filter-green: invert(55%) sepia(27%) saturate(546%) hue-rotate(78deg)
  brightness(87%) contrast(88%);
$filter-red: invert(24%) sepia(91%) saturate(7486%) hue-rotate(1deg)
  brightness(110%) contrast(102%);
